import styled from 'styled-components'
import { Grid } from '../../components/Grid'

export const Wrapper = styled(Grid)`
  margin-bottom: 136px;
  > :first-child {
    grid-column: 1 / span 4;
    align-items: center;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 160px;
    /* This is to span the direct child to full width of the Grid */
    > :first-child {
      grid-column: 1 / span 8;
      justify-content: center;
    }
  }
`
