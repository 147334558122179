import * as React from 'react'
import styled from 'styled-components'
import { displayFont, textFont } from '../styles/typography'
import { Section } from './Section'

interface Props {
  heading: string
  preHeading?: string
  subtitle?: string
  imgSrc?: string
  className?: string
}

const Container = styled(Section)`
  margin-bottom: ${props => props.theme.spacing.default};

  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: ${props => props.theme.spacing.extraLoose};
    margin-bottom: 90px;
  }
`

const PreHeading = styled.p`
  ${textFont('preHeading', 'bold')}
  text-align: center;

  @media only screen and (min-width: 1024px) {
    grid-column: 1 / span 8;
  }
`

const Heading = styled.h2`
  ${displayFont('large', 'heavy')}
  margin-bottom: ${props => props.theme.spacing.tight};
  text-align: center;

  @media only screen and (min-width: 1024px) {
    grid-column: 1 / span 8;
  }
`

const Subtitle = styled.p`
  ${textFont('bodyLarge')}
  text-align: center;
  grid-column: 1 / span 8;
  @media only screen and (min-width: 1024px) {
    grid-column: 2 / span 6;
    ${displayFont('small', 'normal')}
  }
`

const Image = styled.img`
  display: block;
  max-width: 96px;
  margin-bottom: ${props => props.theme.spacing.extraLoose};
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 1024px) {
    max-width: 114px;
    grid-column: 1 / span 8;
  }
`

export const SectionHeader: React.FC<Props> = props => {
  return (
    <Container className={props.className}>
      {props.imgSrc !== undefined && <Image src={props.imgSrc} />}
      {props.preHeading !== undefined && (
        <PreHeading>{props.preHeading}</PreHeading>
      )}
      <Heading>{props.heading}</Heading>
      {props.subtitle !== undefined && <Subtitle>{props.subtitle}</Subtitle>}
    </Container>
  )
}
