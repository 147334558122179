import React, { FC } from 'react'
import { Grid } from './Grid'
import styled from 'styled-components'

interface Props {
  src: string
  imagePosition: 'left' | 'right'
  /** The vertical position of the text on desktop, relative to the image */
  textPosition?: 'start' | 'center' | 'end'
  maxHeight?: number
}

const Container = styled(Grid)`
  /* padding-top: 64px; */
  /* padding-bottom: 64px; */
  align-items: start;
`

type ImagePositionProps = Pick<Props, 'imagePosition'>
type TextPositionProps = Pick<Props, 'textPosition'>

const imageColumnWidth = 3
const contentColumnWidth = 5

function getImageColumns({ imagePosition }: ImagePositionProps): string {
  return imagePosition === 'left'
    ? `1 / span ${imageColumnWidth}`
    : `6 / span ${imageColumnWidth}`
}

function getContentColumns({ imagePosition }: ImagePositionProps): string {
  return imagePosition === 'left'
    ? `4 / span ${contentColumnWidth}`
    : `1 / span ${contentColumnWidth}`
}

const ImageContainer = styled.div<ImagePositionProps>`
  display: none;
  justify-self: ${props => (props.imagePosition === 'left' ? 'right' : 'left')};

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    display: block;
    grid-column: ${getImageColumns};
  }
`

const ContentContainer = styled.div<ImagePositionProps & TextPositionProps>`
  grid-column: 1 / span 8;

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    align-self: ${props => props.textPosition ?? 'start'};
    grid-row-start: 1;
    grid-column: ${getContentColumns};
  }
`

const Img = styled.img<Pick<Props, 'maxHeight'>>`
  border-radius: 99999px;
  max-height: ${props => props.maxHeight}px;
`

export const BubbleImage: FC<Props> = ({
  src,
  imagePosition,
  textPosition,
  maxHeight,
  children
}) => {
  return (
    <Container>
      <ImageContainer imagePosition={imagePosition}>
        <Img maxHeight={maxHeight} src={src} />
      </ImageContainer>
      <ContentContainer
        textPosition={textPosition}
        imagePosition={imagePosition}
      >
        {children}
      </ContentContainer>
    </Container>
  )
}
