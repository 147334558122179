import styled from 'styled-components'
import { textFont, displayFont } from '../styles/typography'

interface ContainerProps {
  /** Arrange the content to the left on mobile */
  isMobileLeft?: boolean
  /** Arrange the content to the left on desktop */
  isDesktopLeft?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: ${props =>
    props.isMobileLeft != null && props.isMobileLeft ? 'flex-start' : 'center'};

  & > * {
    margin-bottom: ${props => props.theme.spacing.tight};
  }
  & > *:last-child {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: ${props =>
      props.isDesktopLeft != null && props.isDesktopLeft
        ? 'flex-start'
        : 'center'};

    /* Provide spacing between each element */
    & > * {
      margin-right: ${props => props.theme.spacing.default};
      margin-bottom: 0;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }
`

export const DisplayText = styled.p`
  ${displayFont('small', 'bold')}
`

interface TextProps {
  /** Wraps the link text to a new line */
  isTight?: boolean
}

export const Text = styled.p<TextProps>`
  ${textFont('body')};

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    a {
      display: ${props =>
        props.isTight != null && props.isTight ? 'block' : 'inline'};
    }
  }
`
