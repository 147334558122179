import styled from 'styled-components'
import { Grid } from '../../components/Grid'
import { displayFont, textFont } from '../../styles/typography'
import { theme } from '../../theme'

export const Layout = styled(Grid)`
  grid-column: 1 / -1;
`

export const Wrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: ${props => props.theme.spacing.loose};
  flex-direction: column-reverse;
  padding: ${props => props.theme.spacing.loose};
  background-color: #ffffff;
  box-shadow: ${theme.elevation.content};
  border-radius: ${theme.borderRadius.large};

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    flex-direction: row;
    justify-content: start;
    padding: ${props => props.theme.spacing.extraLoose};
  }
`

export const TextCol = styled.div`
  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    flex-basis: 80%;
    max-width: 80%;
  }
`

export const ImageCol = styled.div`
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    max-width: 150px;
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    flex-basis: 20%;
    max-width: 20%;
    img {
      max-width: 220px;
    }
  }
`

export const Title = styled.p`
  ${displayFont('small', 'bold')}
  margin-bottom: ${props => props.theme.spacing.snug};
`

export const Body = styled.p`
  ${textFont('bodyLarge')};
  margin-bottom: ${props => props.theme.spacing.loose};

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
  }
`
