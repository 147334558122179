import * as React from 'react'
// import * as Styles from './CompanyExecTeam.styles'
import { SectionHeader } from '../../components/SectionHeader'
import SectionTopIcon from './images/icon.png'
import Richie from './images/Richard-Harrison.png'
import Leon from './images/Leon-Wurfel.png'
import styled from 'styled-components'
import { Grid } from '../../components/Grid'
import { textFont } from '../../styles/typography'
import { theme } from '../../theme'

export const Wrapper = styled.section`
  margin-top: ${props => props.theme.spacing.ultraLoose};
  margin-bottom: 112px;

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    margin-bottom: 90px;
  }
`
/**  ------------  Profile Row  ------------ */
const ProfileRow = styled(Grid)`
  margin-bottom: ${props => props.theme.spacing.ultraLoose};

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    align-items: start;
    margin-bottom: ${props => props.theme.spacing.ultraLoose};
  }
`

const ProfilePhoto = styled.img`
  max-width: 200px;
  width: 100%;
  height: auto;
  grid-column: 1 / span 1;
  border-radius: 50%;
  margin-bottom: ${props => props.theme.spacing.tight};
`

const ProfileContent = styled.div`
  grid-column: 1 / span 4;

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    grid-column: 2 / -1;
    /* max-width: 850px; */
  }
`
/**  ------------  Profile End  ------------ */

/**  ------------  typography  ------------ */
const ExecTitle = styled.p`
  ${textFont('bodyLarge', 'bold')}
  margin-bottom: ${theme.spacing.extraTight};
`
const ExecSummary = styled.div`
  ${textFont('body', 'normal')}
  p {
    margin-bottom: ${theme.spacing.default};
  }
`

export const CompanyExecTeam: React.FC = () => {
  return (
    <>
      <SectionHeader
        heading="Our executive team"
        imgSrc={SectionTopIcon}
        subtitle="We’re passionate about making the complex simple and having a little fun while we’re at it. Our team possesses a unique set of skills that create an unbeatable team"
      />

      <Wrapper>
        <ProfileRow>
          <ProfilePhoto src={Leon} alt="Leon Wurfel" />
          <ProfileContent>
            <ExecTitle>Leon Wurfel (CEO)</ExecTitle>
            <ExecSummary>
              <p>
                Leon is the founder and CEO at Bueno, winner of the ARBS Young
                Achievers award, advisory board member for Realcomm, and
                international smart building speaker. Leon believes that
                prop-tech can disrupt industries with a pro-social outcome and
                has the power to create a more sustainable workforce,
                environment, and synergy between people.
              </p>
              <p>
                He has a true passion for sustainable living and having a
                positive impact trickles through into everything he does at
                Bueno. He aims to disrupt the industry through data and
                purpose-driven innovation.
              </p>
            </ExecSummary>
          </ProfileContent>
        </ProfileRow>

        <ProfileRow>
          <ProfilePhoto src={Richie} alt="Richard Harrison" />
          <ProfileContent>
            <ExecTitle>Richard Harrison (CTO)</ExecTitle>
            <ExecSummary>
              <p>
                Richie is the Chief Technology Officer at Bueno. In this role,
                he is accountable for Bueno’s technology strategy and the
                formulation, development, and delivery of Bueno’s software
                platform. Richard has worked with Australia’s largest
                Telecommunications company Telstra to design and develop Machine
                Learning (ML) pipelines for their Interactive voice response
                (IVR) platform.
              </p>
              <p>
                Richard is passionate about utilizing technology to reduce the
                impact humans have on our environment, as well as fostering a
                culture of mindfulness, compassion, and sustainable work/life
                balance within Bueno.
              </p>
            </ExecSummary>
          </ProfileContent>
        </ProfileRow>
      </Wrapper>
    </>
  )
}
