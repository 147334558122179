import * as React from 'react'
import { Link } from 'gatsby'
import * as Styles from './CompanyCallToAction.styles'
import { CallToAction, Button } from '../../components'

// TODO: This is identical to CustomerCallToAction, should extract out as common pattern when reviewing
export const CompanyCallToAction: React.FC = () => {
  return (
    <Styles.Wrapper>
      <CallToAction.Container>
        <CallToAction.DisplayText>
          Ready for next steps?
        </CallToAction.DisplayText>
        <Button to="/contact">Talk to an expert</Button>
      </CallToAction.Container>
    </Styles.Wrapper>
  )
}
