import * as React from 'react'
import * as Styles from './LargeCallToAction.styles'
import { CallToAction } from '../../components'

interface Props {
  title: string
  body: string
  callToAction: JSX.Element
  image: string
}

export const LargeCallToAction: React.FC<Props> = ({
  title,
  body,
  callToAction,
  image
}) => {
  return (
    <Styles.Layout>
      <Styles.Wrapper>
        <Styles.TextCol>
          <Styles.Title>{title}</Styles.Title>
          <Styles.Body>{body}</Styles.Body>

          <CallToAction.Container isMobileLeft isDesktopLeft>
            {callToAction}
          </CallToAction.Container>
        </Styles.TextCol>

        <Styles.ImageCol>
          <img src={image} />
        </Styles.ImageCol>
      </Styles.Wrapper>
    </Styles.Layout>
  )
}
