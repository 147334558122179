import React, { FC } from 'react'
import * as Hero from '../components/Hero'
import { CompanyExecTeam } from '../modules/CompanyExecTeam'
import { CompanyAwards } from '../modules/CompanyAwards'
import { CompanyCallToAction } from '../modules/CompanyCallToAction'
import { PageLayout } from '../layouts/PageLayout'
import { Title } from '../components/Title'
import aboutUsSrc from '../images/hero/hero-about.jpg'
import { LargeCallToAction } from '../modules/LargeCallToAction'
import imgSrcJobs from '../images/aside-section-2.jpg'
import { BubbleImage } from '../components/BubbleImage'
import facilitiesSrc from '../images/about/img-bubble-about.jpg'
import { theme } from '../theme'
import styled from 'styled-components'
import { Button } from '../components/Button'
import { CallToAction } from '../components'
import { displayFont, textFont } from '../styles/typography'

const Intro = styled.div``
const IntroTitle = styled.h2`
  ${displayFont('large', 'bold')};
  margin-bottom: ${props => props.theme.spacing.default};
`

const IntroBody = styled.div`
  ${textFont('bodyLarge')};

  > p,
  p + p {
    margin-bottom: ${props => props.theme.spacing.default};
  }
`

const Company: FC = () => {
  return (
    <PageLayout>
      <Title title="Company" />

      <Hero.Wrapper src={aboutUsSrc}>
        <Hero.LayoutContent>
          <Hero.LayoutHeading>
            <Hero.Heading>
              Realizing the dream of a sustainable world through data
            </Hero.Heading>
            <Hero.Subheading>
              We work with market leaders to holistically optimize your built
              environment through meaningful insights
            </Hero.Subheading>
          </Hero.LayoutHeading>
        </Hero.LayoutContent>
      </Hero.Wrapper>

      <div
        style={{
          marginTop: `${theme.spacing.ultraLoose}`,
          marginBottom: '56px'
        }}
      >
        <BubbleImage
          src={facilitiesSrc}
          textPosition="center"
          imagePosition="right"
          maxHeight={640}
        >
          <Intro>
            <IntroTitle>Who we are</IntroTitle>
            <IntroBody>
              <p style={{ fontWeight: 500 }}>
                Formally we’re a technology company that improves data
                utilisation in the property sector. But we prefer to call
                ourselves the bright sparks behind brilliant operational
                performance.
              </p>
              <p>
                Data is useless if no one knows how to use it. We’ve seen too
                many buildings generate good data that goes nowhere and achieves
                nothing. Our platform changes that by autonomously monitoring
                millions of data points across hundreds of building systems. We
                connect the dots. We turn intel into action. And we get a kick
                out of doing it.
              </p>
              <p>
                By breaking down big data into bite-sized chunks, Bueno is
                enabling building owners, managers and operators - across a huge
                range of industries - to do more with data. It’s a new frontier
                for operational performance, and we’re proud to lead the way.
              </p>
            </IntroBody>
            <div style={{ marginTop: `${theme.spacing.extraLoose}` }}>
              <CallToAction.Container isDesktopLeft>
                <CallToAction.DisplayText>Intrigued?</CallToAction.DisplayText>
                <Button isInline to="/contact">
                  Let's talk
                </Button>
              </CallToAction.Container>
            </div>
          </Intro>
        </BubbleImage>
      </div>

      <CompanyExecTeam />

      <div style={{ marginTop: '56px', marginBottom: '112px' }}>
        <LargeCallToAction
          title="Interested in joining Bueno?"
          body="Whether you’re a budding engineer, strong salesperson, marketing maverick, or innovative ideas type, we’re always looking for talented people to join our growing team."
          image={imgSrcJobs}
          callToAction={
            <Button
              to="https://www.linkedin.com/company/buenosystems/"
              target="_blank"
              rel="noreferrer"
              backgroundColor="#0a66c2"
            >
              Visit our jobs page on LinkedIn​​
            </Button>
          }
        />
      </div>

      <div style={{ marginTop: '56px', marginBottom: '56px' }}>
        <CompanyAwards />
      </div>

      <CompanyCallToAction />
    </PageLayout>
  )
}

export default Company
