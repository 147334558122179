import * as React from 'react'
import * as Styles from './CompanyAwards.styles'
import imgSrcInnovation from '../../images/about/about-awards-innovation.jpg'
import imgSrcSustainability from '../../images/about/about-awards-sustainability.jpg'
import imgSrcTeam from '../../images/about/about-awards-team.jpg'
// TODO: Text style is inconsistent between mobile and desktop, need to confirm if that's necessary
export const CompanyAwards: React.FC = () => {
  return (
    <Styles.Wrapper>
      <Styles.HeadingSection>
        <Styles.DisplayFont>
          People like what we do, here are some awards to prove it
        </Styles.DisplayFont>
      </Styles.HeadingSection>
      <Styles.AwardContainer>
        <Styles.Middle>
          <Styles.AwardCategory>Innovation</Styles.AwardCategory>
          <Styles.AwardName>Software/Digital Excellence award</Styles.AwardName>
          <Styles.AwardClient>ARBS</Styles.AwardClient>
          <br />
          <Styles.AwardName>Contractor of the Year</Styles.AwardName>
          <Styles.AwardClient>Crown Casino (Melbourne)</Styles.AwardClient>
          <br />
          <Styles.AwardName>IBCON Smart Building Showcase</Styles.AwardName>
          <Styles.AwardClient>
            Queen St East (Toronto), DEXUS Portfolio (Australia), Crown Casino
            (Melbourne)
          </Styles.AwardClient>
          <br />
        </Styles.Middle>
        <Styles.AwardImage src={imgSrcInnovation} />
      </Styles.AwardContainer>

      <Styles.AwardContainer>
        <Styles.Middle>
          <Styles.AwardCategory>Sustainability</Styles.AwardCategory>
          <Styles.AwardName>Environmental Sustainability</Styles.AwardName>
          <Styles.AwardClient>Crown Casino</Styles.AwardClient>
          <br />
          <Styles.AwardName>
            Project Excellence Award | Woolworths EMC Project
          </Styles.AwardName>
          <Styles.AwardClient>ARBS</Styles.AwardClient>
          <br />
          <Styles.AwardName>Innovator of the Year Award</Styles.AwardName>
          <Styles.AwardClient>Climate Alliance</Styles.AwardClient>
          <br />
        </Styles.Middle>
        <Styles.AwardImage src={imgSrcSustainability} />
      </Styles.AwardContainer>

      <Styles.AwardContainer>
        <Styles.Middle>
          <Styles.AwardCategory>Our Team</Styles.AwardCategory>
          <Styles.AwardName>ARBS Young Achiever of the Year</Styles.AwardName>
          <Styles.AwardClient>(Leon Wurfel)</Styles.AwardClient>
          <br />
          <Styles.AwardName>
            EEC Young Professional of the Year{' '}
          </Styles.AwardName>
          <Styles.AwardClient>(Leon Wurfel)</Styles.AwardClient>
          <br />
          <Styles.AwardName>
            REALCOMM IBcon Julie Devine Digital Impact Nomination
          </Styles.AwardName>
          <Styles.AwardClient>(Leon Wurfel)</Styles.AwardClient>
          <br />
        </Styles.Middle>
        <Styles.AwardImage src={imgSrcTeam} />
      </Styles.AwardContainer>
    </Styles.Wrapper>
  )
}
