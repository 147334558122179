import styled from 'styled-components'
import { textFont, displayFont } from '../../styles/typography'
import { Section } from '../../components/Section'
import { theme } from '../../theme'
import { Grid } from '../../components/Grid'

/**  ------------  Grid layout and grid items ------------ */
export const Wrapper = styled(Section)``

export const HeadingSection = styled.div`
  margin-bottom: ${props => props.theme.spacing.loose};
  grid-column: 1/-1;
  /* TODO: solve with the grid */
  max-width: ${theme.breakpoint.mediumScreen};

  @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
    margin-bottom: ${props => props.theme.spacing.extraLoose};
    grid-column: 1 / span 6;
  }
`

/**  ------------  Grid layout and grid items ends  ------------ */

export const AwardContainer = styled(Grid)`
  padding-top: ${theme.spacing.extraLoose};
  padding-bottom: ${theme.spacing.extraLoose};
  align-items: flex-start;
  &:before,
  &:after {
    display: none;
  }
  @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      /* need to match the padding-top from its parent element, plus half its own height */
      top: calc(${theme.spacing.extraLoose} + 12px);
      left: 24px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: ${theme.palette.orange};
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: calc(${theme.spacing.extraLoose} + 12px);
      /* need to be center with the dot */
      /* the dot is 24px wide and 24px from the left */
      /* so the calculation will be left + 1/2 dot's width + 1px */
      /* 1px is taking half of line's width into account */
      left: 35px;
      transform: translateX(-50%);
      width: 2px;
      height: 100%;
      background-color: ${theme.palette.orange};
    }
  }
`

export const Middle = styled.div`
  grid-column: 1 / -1;
  @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
    grid-column: 2 / span 4;
  }
`

export const AwardCategory = styled.p`
  ${displayFont('medium', 'bold')}
  margin-bottom: ${theme.spacing.snug};
  /* @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
    ${displayFont('large', 'bold')}
  } */
`
export const AwardName = styled.p`
  ${textFont('bodyLarge', 'semiBold')}
`

export const AwardClient = styled.p`
  ${textFont('bodyLarge', 'normal')}
`

export const AwardImage = styled.img`
  max-width: 219px;
  @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
    grid-column: 7 / -1;
  }
`
/**  ------------  typography  ------------ */
export const DisplayFont = styled.p<{ greyFont?: boolean }>`
  ${displayFont('medium', 'bold')};
  color: ${props =>
    props.greyFont != null && props.greyFont ? '#A0B1BC' : '#000'};
  margin-bottom: ${props => props.theme.spacing.default};
  strong {
    font-weight: 700;
  }
  br {
    display: none;
  }
  @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
    ${displayFont('large', 'bold')};
    margin-bottom: ${props => props.theme.spacing.snug};
    br {
      display: block;
    }
  }
`
export const Body = styled.p`
  ${textFont('body', 'normal')}
  font-style: italic;
  strong {
    font-weight: 700;
    font-style: normal;
  }
  margin-bottom: ${props => props.theme.spacing.default};
  @media only screen and (min-width: ${theme.breakpoint.mediumScreen}) {
    ${textFont('bodyLarge', 'normal')}
  }
`

/**  ------------  typography ends  ------------ */
