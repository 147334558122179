import React, { FC } from 'react'
import styled from 'styled-components'
import { Grid } from './Grid'
import { displayFont } from '../styles/typography'
import { theme } from '../theme'

interface BGProps {
  src: string
}

const WrapperBg = styled.div<BGProps>`
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    background-size: contain;
  }
`

export const Wrapper: FC<BGProps> = ({ src, children }) => (
  <WrapperBg src={src}>{children}</WrapperBg>
)

export const LayoutContent = styled.div`
  padding-top: ${theme.spacing.ultraLoose};
  padding-bottom: ${theme.spacing.loose};
  width: 100%;
  max-width: ${theme.dimensions.app.maxWidth};
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    padding-top: 160px;
    padding-bottom: ${theme.spacing.ultraLoose};
  }
`

export const LayoutHeading = styled(Grid)``

export const Heading = styled.h1`
  ${displayFont('large', 'bold')};
  grid-column: 1 / -1;
  margin-bottom: ${props => props.theme.spacing.default};

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    ${displayFont('xLarge', 'bold')};
    grid-column: 1 / span 6;
    margin-bottom: ${props => props.theme.spacing.extraLoose};
  }
`
export const Subheading = styled.h2`
  ${displayFont('small')};
  margin-bottom: ${props => props.theme.spacing.extraLoose};
  grid-column: 1 / -1;

  strong {
    font-weight: ${theme.typography.weight.bold};
  }

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    grid-column: 1 / span 5;
  }
`
